<template>
  <div class="sel-calltype">
    <div class="title">请选择类型</div>
    <sel-call-type-card v-for="item in this.callTypes" v-bind:key="item.id" :role="item"
                      @pageTo="pageTo"></sel-call-type-card>
    <!-- 底部导航栏-->
<!--    <crm-tab-bar :tabpage="2"/>-->
  </div>
</template>

<script>
import SelCallTypeCard from "@/components/SelCalltype/index"
import CrmTabBar from "@/components/CrmTabBar";

export default {
  name: "FrequencyRemindSelect",
  components: {CrmTabBar, SelCallTypeCard},
  data() {
    return {
      callTypes: [
        {
          id: '0',
          roleName: '电话',
          to: '/FrequencyRemindPhone/'+this.$route.params.id,
          portrait: '电话.png',
          use: true,
        },
        {
          id: '1',
          roleName: '走访',
          to: '/FrequencyRemindGo/'+this.$route.params.id,
          portrait: '走访.png',
          use: false,
        },
        {
          id: '3',
          roleName: '其他',
          to: '/FrequencyRemindOther',
          portrait: '其他.png',
          use: false,
        }
      ]
    }
  },
  created() {
  },
  methods: {
    pageTo(to) {
      console.log(to)
      this.$router.replace({path: to})
    },
  }
}
</script>

<style scoped>
.sel-calltype{
  background: white;
}

.sel-calltype .title {
  margin-top: 60px;
  font-size: 24px;
  color: #101010;
  text-align: center;
}
</style>